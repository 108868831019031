"use client";
import React, { FC } from "react";
import Image from "next/image";

import { ThemedPage } from "@/components";
import { Main, Module } from "@/components/layouts";
import PageHeading from "@/components/page-heading";

type ErrorPageProps = {
  error: Error & { digest?: string };
};

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  const { message, digest } = error;

  return (
    <ThemedPage>
      <Main small>
        <PageHeading>Oh no! Something broke!</PageHeading>

        <Module>
          <div className="split-layout">
            <div className="split-layout--small">
              <Image
                src="/images/villain.jpg"
                alt=""
                width={640}
                height={790}
                style={{ width: "100%" }}
              />
            </div>
            <div className="split-layout--large">
              <p>Whoops! Very sorry, something went horribly wrong.</p>
              <p>The error said &quot;{message}&quot;</p>
              <p>You&apos;ll need to refresh the page to get going again.</p>
              <p>Code: {digest}</p>
            </div>
          </div>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default ErrorPage;
